import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource-variable/mulish/index.css'

import { type CardOnboardingSessions } from '@brightwell/types'
import { cssBundleHref } from '@remix-run/css-bundle'
import {
	type HeadersFunction,
	type LinksFunction,
	json,
	redirect,
	type LoaderFunctionArgs,
	type MetaFunction
} from '@remix-run/node'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useRouteError,
	useSearchParams,
} from '@remix-run/react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zx } from 'zodix'
import TrackingProvider from './lib/tracking/tracking-provider.tsx'
import { getOnboardingSession } from './models/card-onboarding-sessions.server.ts'
import { login } from './services/api/login.ts'
import appStylesheetUrl from './styles/app.css?url'
import fontStylesheetUrl from './styles/font.css?url'
import tailwindStylesheetUrl from './styles/tailwind.css?url'
import { getEnv } from './utils/env.server.ts'
import { getDomainUrl } from './utils/misc.server.ts'
import { useNonce } from './utils/nonce-provider.tsx'
import { DefaultCardBackground } from './utils/paramUtils.ts'
import { makeTimings, time } from './utils/timings.server.ts'

export const handle = {
	i18n: 'common',
}

export async function loader({ request, params }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const isV1 = request.url.includes('AddCard')
	const { onboardingSessionId } = !isV1
		? zx.parseParams(params, {
			onboardingSessionId: z.string().optional(),
		})
		: { onboardingSessionId: params.addCardId }

	let customization:
		| CardOnboardingSessions['customization']
		| null
		| undefined = null

	if (isV1) {
		if (!params.addCardId) return redirect('/error')
		const session = await login(params.addCardId)
		if (!session) return redirect('/error')

		if (onboardingSessionId && session) {
			const onboarding = await time(
				getOnboardingSession(session, onboardingSessionId),
				{
					timings,
					type: 'getOnboardingSession',
				},
			)

			customization = onboarding?.customization
		}
	}

	const path = new URL(request.url).pathname

	const headers: HeadersInit = new Headers()
	headers.append('Server-Timing', timings.toString())
	const locale = isV1
		? customization?.locale ?? 'en-US'
		: new URL(request.url).searchParams.get('lng')

	return json(
		{
			requestInfo: {
				origin: getDomainUrl(request),
				path,
				locale,
			},
			ENV: getEnv(),
			session: {
				customization,
			},
		},
		{
			headers,
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	return {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
}

export const meta: MetaFunction = () => [
	{
		charSet: 'utf-8',
	},
	{
		title: 'Add Card',
	},
	{
		name: 'viewport',
		content: 'width=device-width,initial-scale=1',
	},
]

export const links: LinksFunction = () => {
	return [
		{ rel: 'preload', href: fontStylesheetUrl, as: 'style' },
		{ rel: 'preload', href: tailwindStylesheetUrl, as: 'style' },
		{ rel: 'preload', href: appStylesheetUrl, as: 'style' },
		cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
		{ rel: 'stylesheet', href: fontStylesheetUrl },
		{ rel: 'stylesheet', href: tailwindStylesheetUrl },
		{ rel: 'stylesheet', href: appStylesheetUrl },
		cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null,
	].filter(Boolean)
}

function formatButtonCasingStyles(buttonCasing: string) {
	switch (buttonCasing) {
		case 'Upper':
			return 'uppercase'
		case 'Lower':
			return 'lowercase'
		case 'Capital':
			return 'capitalize'
		default:
			return 'none'
	}
}

export default function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const { i18n } = useTranslation()
	const [searchParams] = useSearchParams({
		font: 'sans',
		primary: '#934AE0',
		primaryLight: 'rgba(147, 74, 224, 0.1)',
		text: '#0E0F0C',
		textSecondary: '#454545',
		foreground: '#FFFFFF',
		background: '#F3F4F6',
		inputLine: '#858585',
		divider: '#E2E2E2',
		icon: '#444444',
		success: '#008761',
		danger: '#AA220F',
		dangerLight: 'rgba(170, 34, 15, 0.1)',
		cardBackground: DefaultCardBackground,
		buttonText: '#FFFFFF',
		buttonColor: '#FF8309',
		buttonRadius: '999px',
		buttonTextCase: 'none',
		buttonFont: 'sans',
		headlineFont: 'sans',
		buttonLetterSpacing: 'initial',
	})

	const customization = data.session.customization
	const themeVars: React.CSSProperties = React.useMemo(() => {
		const theme: Record<string, string> = {}

		searchParams.forEach((value, key) => {
			if (!value) return

			try {
				theme[key] = value
			} catch (error) {
				console.info(`Invalid value for ${key}: ${value}`, error)
			}
		})
		const defaultFallbackFonts =
			'"Helvetica Neue", Arial, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'

		return {
			'--color-icon': theme.icon,
			'--color-primary': theme.primary,
			'--color-primary-text': theme.text,
			'--color-primary-light': theme.primaryLight,
			'--color-secondary-text': theme.textSecondary,
			'--color-divider': theme.divider,
			'--color-danger': theme.danger,
			'--color-danger-light': theme.dangerLight,
			'--color-success': theme.success,
			'--color-subtle': theme.inputLine,
			'--color-background': theme.background,
			'--color-foreground': theme.foreground,
			'--font-family': customization?.texts?.family,
			'--card-background': theme?.cardBackground,
			'--color-button-background': theme.buttonColor,
			'--color-button-background-secondary-focus': `${theme.buttonColor}2A`,
			'--color-button-background-light': `${theme.buttonColor}1A`,
			'--color-button-text': theme.buttonText,
			'--radius-button': theme.buttonRadius ?? '9999px',
			'--font': `'${theme.font}', ${defaultFallbackFonts}`,
			'--font-button': `'${theme.buttonFont}', ${defaultFallbackFonts}`,
			'--font-headline': `'${theme?.headlineFont}', ${theme.font}, ${defaultFallbackFonts}`,
			'--transform-button-text': formatButtonCasingStyles(theme.buttonTextCase),
			'--button-letter-spacing': theme.buttonLetterSpacing ?? 'initial',
		}
	}, [customization, searchParams])
	const alignTop = searchParams.get('alignTop') === '1' || searchParams.get('alignTop') === 'true' 
	return (
		<html
			className="h-full font-sans"
			dir={i18n.dir()}
			lang={data.requestInfo.locale ?? 'en-US'}
			style={{
				...themeVars,
			}}
		>
			<head>
				<Meta />
				<Links />
			</head>
			<body className={`min-h-full bg-foreground ${alignTop ? 'content-start' : 'content-center'}`}>
				<TrackingProvider connectionString={data.ENV.APPLICATIONINSIGHTS_CONNECTION_STRING}>
					<Outlet />
				</TrackingProvider>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<script
					crossOrigin="anonymous"
					integrity="sha512-R7Piufj0/o6jG9ZKrAvS2dblFr2kkuG4XVQwStX+/4P+KwOLUXn2DXy0l1AJDxxqGhkM/FJllZHG2PKOAheYzg=="
					nonce={nonce}
					referrerPolicy="no-referrer"
					src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.6/iframeResizer.contentWindow.min.js"
				/>
			</body>
		</html>
	)
}

export function ErrorBoundary() {
	const error = useRouteError()
	const isOfflineError = (error as Error).message === 'Failed to fetch'

	if (isOfflineError) {
		location.reload()
		return null
	}

	if (error) {
		throw error
	}
}
