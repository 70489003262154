import {
  ReactPlugin,
  AppInsightsContext,
} from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import React, { useEffect } from 'react'

const reactPlugin = new ReactPlugin()

const config = {
  autoTrackPageVisitTime: true,
  enableAutoRouteTracking: true,
  extensions: [reactPlugin],
  instrumentationKey: '',
};

type Props = {
  children: React.ReactNode,
  connectionString: string
}

export default function TrackingProvider({ children, connectionString }: Props) {
  useEffect(() => {
    if (connectionString) {
      const appInsights = new ApplicationInsights({
        // @ts-ignore
        config: {
          ...config,
          connectionString
        },
      })
      appInsights.loadAppInsights()
      appInsights.trackPageView()
    }
  }, [connectionString])

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  )
}
